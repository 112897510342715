import React from 'react'
import { XBlock } from 'react-xmasonry'

import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useVisibleItems } from '@hooks/useVisibleItems'
import { IntoUrl } from '@models/IntoUrl'
import { useTrackContentSelection } from '@tracking/events/useTrackContentSelection'
import { useTrackGridViews } from '@tracking/events/useTrackGridViews'
import { UrlGridItem, UrlGridItemLoading } from './UrlGridItem'

export const UrlGridLoadingElements = ({ limit }: { limit: number }) =>
  new Array(limit)
    .fill(undefined)
    .map((el, index) => <UrlGridItemLoading height={'aspect-square'} key={`urls-loading-${index}`} />)

export const UrlGridLoading = (props: { limit: number }) => <>{UrlGridLoadingElements(props)}</>

interface UrlGridProps {
  urls: IntoUrl[]
  layout: 'masonry' | 'grid'
  isTitleEnabled: boolean
  maxImageWidth: number
  onClick?: (url_id: string) => void
  likedByUsername: string | undefined
  pageSection: string
  node: string
  streamUrlId: string | number | null
}

export const UrlGrid = (props: Omit<UrlGridProps, 'layout' | 'maxImageWidth'>) => (
  <>{UrlGridElements({ ...props, layout: 'grid', maxImageWidth: 250 })}</>
)

export const UrlGridWithLoading = (
  props: Omit<UrlGridProps, 'layout' | 'maxImageWidth'> & { isLoading: boolean; limit: number }
) => {
  if (props.isLoading) return <UrlGridLoading limit={props.limit} />
  return <UrlGrid {...props} />
}

export const UrlGridElements = ({
  urls,
  layout,
  isTitleEnabled,
  maxImageWidth,
  onClick,
  likedByUsername,
  pageSection,
  node,
  streamUrlId,
}: UrlGridProps) => {
  const trackClick = useTrackClick()
  const trackGridView = useTrackGridViews()
  const trackContentSelection = useTrackContentSelection()
  const { onItemVisibilityChanged, maxIndexSeen, numItemsInViewport } = useVisibleItems()

  const gridItem = (url: IntoUrl, index: number) => {
    return (
      <UrlGridItem
        key={url.url_id}
        url={url}
        index={index}
        layout={layout}
        likedByUsername={likedByUsername}
        isTitleEnabled={isTitleEnabled}
        maxImageWidth={maxImageWidth}
        onClick={onClick ? () => onClick(url.url_id) : undefined}
        onClickSideEffect={isMetaKeyPressed => {
          trackClick('urlItem', { current_page_section: pageSection })
          trackContentSelection({
            urlID: url.url_id,
            index,
            maxIndexSeen,
            numItemsInViewport,
            isMetaKeyPressed,
            pageSection,
          })
        }}
        onVisibilityChanged={inView => {
          onItemVisibilityChanged(index, inView)
          trackGridView(url.url_id, index, inView, {
            recId: url.meta.recId,
            recBatchId: url.meta.recBatchId,
            pageSection,
            node,
            streamUrlId,
          })
        }}
      />
    )
  }

  return urls.map((url, index) => {
    return layout === 'masonry' ? (
      <XBlock key={url.url_id}>
        <div className="m-1.5">{gridItem(url, index)}</div>
      </XBlock>
    ) : (
      gridItem(url, index)
    )
  })
}
